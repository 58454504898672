@import url("https://fonts.googleapis.com/css?family=Poppins");
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*!
 * !!! PARTIAL !!! Bootstrap Reboot v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box; }

@-ms-viewport {
  width: device-width; }

body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

a {
  text-decoration: none;
  background-color: transparent; }

img {
  vertical-align: middle;
  border-style: none; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

[hidden] {
  display: none !important; }

html {
  height: 100%; }

body {
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  height: 100vh;
  margin: 0;
  background-color: #fff; }

.client {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 60px; }
  .client .hero {
    text-align: center;
    margin: 30px 0; }
    .client .hero img {
      margin-top: 30px;
      width: 100%;
      height: auto; }
  .client .resources {
    display: flex;
    margin: 30px 0; }
    .client .resources .resource {
      background-color: #C1466B;
      width: 120px;
      height: 120px;
      margin: 10px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center; }
  .client .project {
    margin: 30px 0; }
    .client .project .info {
      margin: 15px 0; }
      .client .project .info .title {
        margin-bottom: 10px; }
  .client .blockquote {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 30px 0; }
    .client .blockquote .quote {
      width: 100%;
      display: flex;
      position: absolute;
      top: 25%;
      left: 15%;
      text-align: left; }
      @media (min-width: 64.0625em) {
        .client .blockquote .quote {
          width: 50%;
          top: 33%; } }
      .client .blockquote .quote .block {
        font-size: 24px;
        margin-right: 15px; }
  .client .more {
    margin: 30px 0; }
    .client .more .paragraph {
      margin: 10px 0; }

.clients-and-partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }
  .clients-and-partners .content {
    display: inline-block;
    width: 80%;
    padding-bottom: 30px; }
    .clients-and-partners .content .title {
      margin-bottom: 60px; }
    .clients-and-partners .content .description {
      text-align: left;
      margin-bottom: 60px; }
  .clients-and-partners .clients {
    display: flex;
    flex-wrap: wrap;
    margin: 60px 0; }
    .clients-and-partners .clients .client {
      width: 100%;
      margin: 30px 0;
      padding: 0; }
      @media (min-width: 48em) {
        .clients-and-partners .clients .client {
          width: 50%;
          padding: 0 60px; } }
      .clients-and-partners .clients .client .image {
        padding: 0 15px; }
        .clients-and-partners .clients .client .image img {
          width: 100%;
          height: auto; }
      .clients-and-partners .clients .client .title {
        width: 100%;
        height: 100%;
        border-top: 1px solid #C1466B;
        display: flex;
        align-items: center;
        justify-content: center; }

.button {
  margin-right: 15px; }
