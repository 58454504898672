@import url("https://fonts.googleapis.com/css?family=Poppins");
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*!
 * !!! PARTIAL !!! Bootstrap Reboot v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box; }

@-ms-viewport {
  width: device-width; }

body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

a {
  text-decoration: none;
  background-color: transparent; }

img {
  vertical-align: middle;
  border-style: none; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

[hidden] {
  display: none !important; }

html {
  height: 100%; }

body {
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  height: 100vh;
  margin: 0;
  background-color: #fff; }

.input {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: 15px;
  min-height: 90px; }
  .input input, .input textarea {
    height: 43px;
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 1.5;
    color: #000000;
    border: 1px solid #73716C;
    outline: none;
    border-radius: 3px;
    padding: 10px;
    transition: 0.3s ease-in-out; }
    .input input:focus, .input textarea:focus {
      color: #000000;
      border: 1px solid #73716C; }
    .input input.error, .input textarea.error {
      color: #FF4343;
      border: 2px solid #E53E3E; }
  .input .subtext {
    font-size: 12px;
    padding-top: 10px; }
  .input .subtext-hide {
    line-height: 10px;
    height: 25px; }
  .input textarea {
    height: 200px; }

.black {
  color: #000000; }

.rose {
  color: #f8c1ce; }

.white {
  color: #FFFFFF; }

.darkest-blue {
  color: #29324D; }

.label {
  width: 100%;
  display: block;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 5px; }
  @media (min-width: 48em) {
    .label {
      font-size: 17px; } }
  @media (min-width: 64.0625em) {
    .label {
      font-size: 14px; } }

.error-message {
  color: #FF4343;
  font-size: 12px;
  line-height: 1.5;
  margin-top: 5px; }
