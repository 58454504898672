@import url("https://fonts.googleapis.com/css?family=Poppins");
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*!
 * !!! PARTIAL !!! Bootstrap Reboot v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box; }

@-ms-viewport {
  width: device-width; }

body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

a {
  text-decoration: none;
  background-color: transparent; }

img {
  vertical-align: middle;
  border-style: none; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

[hidden] {
  display: none !important; }

html {
  height: 100%; }

body {
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  height: 100vh;
  margin: 0;
  background-color: #fff; }

.contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }

.map-and-address {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 60px 0; }
  @media (min-width: 64.0625em) {
    .map-and-address {
      flex-direction: row; } }
  .map-and-address .address {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
    background-color: #C1466B;
    padding: 50px;
    color: #FFFFFF; }
    .map-and-address .address .line {
      margin: 10px 0; }
      .map-and-address .address .line .line-one {
        margin-bottom: 5px; }
  .map-and-address .map {
    width: 100%; }
    .map-and-address .map img {
      width: 100%;
      height: auto;
      max-width: 100%; }

.social {
  width: 100%;
  display: flex;
  flex-direction: column; }
  @media (min-width: 48em) {
    .social {
      width: 50%; } }
  .social .social-links {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 60px 0 0 0; }
    .social .social-links .social-link {
      background-color: #C1466B;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .social .social-links .social-link a {
        color: #FFFFFF; }

.help {
  width: 100%;
  margin: 60px 0;
  padding-bottom: 30px; }
  @media (min-width: 48em) {
    .help {
      width: 80%;
      padding: 0 70px; } }
  .help .description > p > a {
    color: #000000;
    text-decoration: underline; }

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 120px;
  width: 100%; }
  @media (min-width: 48em) {
    .form {
      padding: 0 70px;
      width: 80%; } }
  .form .form-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    margin-bottom: 15px; }
    @media (min-width: 64.0625em) {
      .form .form-group {
        flex-direction: row; }
        .form .form-group .input:nth-child(even) {
          margin-left: 15px; } }
  .form .actions {
    display: flex;
    width: 100%;
    justify-content: center; }

.toast {
  background: #e8668d;
  margin: auto;
  max-width: 500px;
  text-align: center;
  padding: 5px 15px;
  border-radius: 3px; }
  @media (min-width: 48em) {
    .toast {
      padding: 5px 20px; } }
