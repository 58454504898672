@import url("https://fonts.googleapis.com/css?family=Poppins");
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*!
 * !!! PARTIAL !!! Bootstrap Reboot v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box; }

@-ms-viewport {
  width: device-width; }

body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

a {
  text-decoration: none;
  background-color: transparent; }

img {
  vertical-align: middle;
  border-style: none; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

[hidden] {
  display: none !important; }

html {
  height: 100%; }

body {
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  height: 100vh;
  margin: 0;
  background-color: #fff; }

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }
  .home .hero {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: center;
    margin-top: 20px; }
    .home .hero .image {
      width: 100%;
      margin: 30px 0; }
      @media (min-width: 48em) {
        .home .hero .image {
          width: 70%; } }
  .home .services {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px; }
    .home .services .header {
      margin: 30px 0; }
      .home .services .header .title {
        margin-bottom: 30px; }
    .home .services .description {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .home .services .description .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 1000px;
        width: 100%; }
        @media (max-width: 47.99em) {
          .home .services .description .wrapper {
            padding: 0 30px; } }
    .home .services .cards {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      align-items: center;
      justify-content: center; }
      @media (min-width: 64.0625em) {
        .home .services .cards {
          flex-direction: row; } }
      .home .services .cards .service-container {
        background-color: transparent;
        width: 300px;
        height: 300px;
        perspective: 1000px; }
        .home .services .cards .service-container:hover .service {
          transform: rotateY(180deg); }
        @media (min-width: 48em) {
          .home .services .cards .service-container {
            width: 600px;
            height: 300px; } }
        @media (min-width: 64.0625em) {
          .home .services .cards .service-container {
            width: 300px;
            height: 300px; } }
        .home .services .cards .service-container .service {
          position: relative;
          width: 100%;
          height: 100%;
          transition: transform 0.8s;
          transform-style: preserve-3d; }
          .home .services .cards .service-container .service .front, .home .services .cards .service-container .service .back {
            position: absolute;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
            transform: rotateY(0deg); }
          .home .services .cards .service-container .service .front {
            padding: 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center; }
            .home .services .cards .service-container .service .front .title {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%; }
            .home .services .cards .service-container .service .front .image {
              padding: 20px; }
              .home .services .cards .service-container .service .front .image img {
                width: 100%;
                height: auto; }
          .home .services .cards .service-container .service .back {
            transform: rotateY(180deg);
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center; }
        .home .services .cards .service-container .fuchsia {
          background-color: #f9bbd7; }
        .home .services .cards .service-container .light-fuchsia {
          background-color: #fdd8e9; }
        .home .services .cards .service-container .light-peach {
          background-color: #fde6e6; }
        .home .services .cards .service-container .lightest-peach {
          background-color: #fbf1f1; }

.content {
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.help {
  width: 100%;
  margin: 30px 0; }
  .help .description {
    text-align: left; }
  .help .description > p > a {
    color: #000000;
    text-decoration: underline; }
  @media (min-width: 48em) {
    .help {
      padding: 0 70px;
      width: 80%; } }

.form {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto; }
  .form .form-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    margin-bottom: 15px; }
    @media (min-width: 64.0625em) {
      .form .form-group {
        flex-direction: row; }
        .form .form-group .input:nth-child(even) {
          margin-left: 15px; } }
  .form .actions {
    display: flex;
    width: 100%;
    justify-content: center; }

.step {
  padding: 5%;
  display: flex;
  flex-direction: column; }
  @media (min-width: 48em) {
    .step {
      flex-direction: row; }
      .step:nth-child(even) {
        flex-direction: row-reverse; } }
  .step .image {
    width: 100%;
    align-self: center;
    padding: 30px; }
    @media (min-width: 48em) {
      .step .image {
        width: 60%; } }
    .step .image img {
      width: 100%;
      height: auto; }
  .step .content {
    width: 100%;
    text-align: left; }
    .step .content .markup {
      margin-top: 30px;
      text-align: center; }
      @media (min-width: 48em) {
        .step .content .markup {
          text-align: left; } }
      .step .content .markup .text {
        font-size: 18px; }
        @media (min-width: 48em) {
          .step .content .markup .text {
            font-size: 22px; } }

.section {
  width: 100%;
  text-align: center;
  padding: 5%; }
  .section .description {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .section .description .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 1000px;
      width: 100%; }
      @media (max-width: 47.99em) {
        .section .description .wrapper {
          padding: 0 30px; } }
      .section .description .wrapper .text {
        font-size: 18px; }
        @media (min-width: 48em) {
          .section .description .wrapper .text {
            font-size: 26px; } }
  .section .actions {
    padding-top: 15px;
    display: flex;
    justify-content: center; }

.clients-and-partners {
  margin: 30px 0; }
  .clients-and-partners .images {
    margin: 30px 0; }
    .clients-and-partners .images .image {
      margin: 0 auto;
      padding: 10px;
      max-width: 265px;
      height: 265px;
      display: flex;
      align-items: center; }
      @media (min-width: 48em) {
        .clients-and-partners .images .image {
          padding: 50px; } }
      .clients-and-partners .images .image img {
        width: 100%;
        height: auto;
        vertical-align: middle; }
    .clients-and-partners .images .active {
      border-bottom: 3px solid #C1466B; }
  .clients-and-partners .testimonial {
    width: 100%;
    background-color: #F9FAFC;
    display: flex !important;
    flex-direction: column;
    padding: 10px;
    padding-bottom: 15px; }
    @media (min-width: 48em) {
      .clients-and-partners .testimonial {
        flex-direction: row;
        padding: 30px; } }
    .clients-and-partners .testimonial .blockquote {
      text-align: center; }
      @media (min-width: 48em) {
        .clients-and-partners .testimonial .blockquote {
          margin-right: 25px;
          text-align: left; } }
  .clients-and-partners .author {
    background-color: #F9FAFC;
    padding-bottom: 30px; }
