@import url("https://fonts.googleapis.com/css?family=Poppins");
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*!
 * !!! PARTIAL !!! Bootstrap Reboot v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box; }

@-ms-viewport {
  width: device-width; }

body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

a {
  text-decoration: none;
  background-color: transparent; }

img {
  vertical-align: middle;
  border-style: none; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

[hidden] {
  display: none !important; }

html {
  height: 100%; }

body {
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  height: 100vh;
  margin: 0;
  background-color: #fff; }

.about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }
  .about-us .our-story .description {
    text-align: justify;
    margin: 30px 0 60px 0; }
  .about-us .team-members {
    margin-bottom: 60px; }
    .about-us .team-members .title {
      margin-bottom: 20px; }
    .about-us .team-members .members {
      display: flex;
      flex-wrap: wrap; }
      @media (max-width: 47.99em) {
        .about-us .team-members .members {
          justify-content: center; } }
      .about-us .team-members .members .team-member {
        width: 100%;
        margin: 15px; }
        @media (min-width: 48em) {
          .about-us .team-members .members .team-member {
            width: 45%; } }
        @media (min-width: 64.0625em) {
          .about-us .team-members .members .team-member {
            width: 30%; } }
        .about-us .team-members .members .team-member .name {
          min-height: 30px; }
        .about-us .team-members .members .team-member .image {
          padding: 20px; }
          .about-us .team-members .members .team-member .image img {
            width: 100%;
            height: auto; }
  .about-us .current-openings {
    margin-bottom: 60px; }
    .about-us .current-openings .join .title {
      margin-bottom: 30px; }
    .about-us .current-openings .join .description {
      text-align: left; }
    .about-us .current-openings .openings {
      text-align: left;
      margin: 30px 0; }
      .about-us .current-openings .openings .jobs {
        display: flex;
        flex-direction: column; }
        .about-us .current-openings .openings .jobs .headers {
          display: flex;
          justify-content: space-between; }
          .about-us .current-openings .openings .jobs .headers h4 {
            width: 100%;
            padding: 30px 0;
            border-bottom: 1px solid #E5E5E5; }
        .about-us .current-openings .openings .jobs .job {
          width: 100%;
          display: flex;
          justify-content: space-between; }
          .about-us .current-openings .openings .jobs .job div {
            width: 100%; }
          .about-us .current-openings .openings .jobs .job .position, .about-us .current-openings .openings .jobs .job .specialty, .about-us .current-openings .openings .jobs .job .location {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 30px 0;
            border-bottom: 1px solid #E5E5E5; }
