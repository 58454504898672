@import url('https://fonts.googleapis.com/css?family=Poppins');

// sizes
$font-size-mobile-h1: 30px;
$font-size-desktop-h1: 42px;
$font-size-mobile-h2: 26px;
$font-size-desktop-h2: 30px;
$font-size-mobile-h3: 20px;
$font-size-desktop-h3: 26px;
$font-size-mobile-h4: 18px;
$font-size-desktop-h4: 20px;
$font-size-mobile-h5: 14px;
$font-size-desktop-h5: 18px;
$font-size-mobile-large-body: 18px;
$font-size-desktop-large-body: 18px;
$font-size-mobile-regular-body: 17px;
$font-size-desktop-regular-body: 17px;
$font-size-desktop-small-body: 14px;
$font-size-mobile-small-body: 14px;
$font-size-desktop-tiny-body: 12px;
$font-size-mobile-tiny-body: 12px;

// weights
$font-weight-bold: 700;
$font-weight-semi-bold: 500;
$font-weight-regular: 400;
$font-weight-light: 300;

// families
$font-family:"Poppins", sans-serif;
// line-height
$line-height: 1.5;
