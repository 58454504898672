// site colors
$white: #FFFFFF;
$black: #000000;
$aqua: #00FFB2;
$dark-aqua: #00F2AD;
$rose: #f8c1ce;
$dark-rose: #f76f8e;
$blue: #184A9C;
$dark-blue: #0a5299;
$darkest-blue: #29324D;
$red: #FF4343;
$dark-red: #E53E3E;
$violet: #623cea;
$off-white: #f8f4f3;
$lavender: #9f88f0;
$dark-slate-gray: #004a64;
$turquoise-blue: #00b9d1;
$light-aqua: #97fae9;
$dodger-blue: dodgerblue;
$fuchsia: #C1466B;
$light-fuchsia: #e8668d;
$super-light-grey: #F5F5F5;
$lightest-grey: #F9FAFC;
$light-grey: #E5E5E5;
$medium-grey: #D8D6D0;
$dark-grey: #B5B4AE;
$darkest-grey: #73716C;
